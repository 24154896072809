<template>
  <div>
    <v-row class="align-center mb-4">
      <v-col>
        <v-row class="align-center">
          <v-col>
            Всего записей: {{ data.length }}

            <v-tooltip
              right
              color="primary"
              v-if="hasData"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon class="ml-2" @click="showAllData" v-on="on">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>

              <span>Обновить список</span>
            </v-tooltip>
          </v-col>

          <v-col>
            <v-switch
              :disabled="!hasData"
              v-model="active"
              label="Активные"
            ></v-switch>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="6">
        <v-layout align-center>
          <v-text-field
            v-model="searchText"
            label="Поиск"
            :placeholder="placeholder"
            hide-details
            class="mr-4"
          ></v-text-field>
        </v-layout>
      </v-col>
    </v-row>

    <div :style="{height: innerHeight + 'px'}">
      <template v-if="!loadingData">
        <template v-if="hasData && filteredList.length">
          <v-simple-table :height="innerHeight" fixed-header>
            <thead>
              <tr>
                <th width="30">Выбор</th>
                <th>Номер</th>
                <th>Название</th>
                <th>Доп.поле</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in filteredList" :key="item.id">
                <td class="md1">
                  <v-checkbox
                    class="ma-0"
                    hide-details
                    v-model="item.checked"
                    @change="setStatus(item)"
                  ></v-checkbox>
                </td>
                <td>
                  {{ item.number }}
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.addition }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </template>

        <template v-else-if="hasData && filteredList.length === 0">
          <v-sheet
            class="fill-height py-8 text-center"
            style="position: relative; border: 1px dashed #cecece"
          >
            Ничего не найдено
          </v-sheet>
        </template>

        <template v-else>
          <v-sheet
            class="fill-height py-8"
            style="position:relative; border: 1px dashed #cecece"
          >
            <v-btn
              outlined
              absolute
              color="grey"
              @click="showAllData"
              style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
            >
              Показать все записи
            </v-btn>
          </v-sheet>
        </template>
      </template>

      <div v-else class="pa-4">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapLayerData',
  props: {
    // набор дефолтных значений параметров
    url: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Введите название',
    },
    // свойство, передаваемое через v-model
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      innerHeight: 300,
      active: false,
      hasData: false,
      loadingData: false,
      data: [],
      searchText: '',
    };
  },
  computed: {
    // Список, фильтированный по статусу активности и введенной строке поиска
    filteredList: function() {
      return this.data.filter(item => {
        // Проверка на активность
        if (this.active) {
          if (this.itemIsChecked(item) === -1) {
            return false;
          }
        }

        // Проверка на введенную строку
        if (this.searchText) {
          return this.checkByStr(item);
        }

        // На всякий случай - установка текущего статуса активности
        item.checked = this.itemIsChecked(item) !== -1;

        return true;
      });
    }
  },
  methods: {
    // Проверка элемента на соответствие введенной строке
    checkByStr: function(item) {
      let strSearch = new RegExp(this.searchText, 'i');

      return (strSearch.test(item.number) || strSearch.test(item.title));
    },
    // Проверка зачекан элемент или нет
    itemIsChecked: function(item) {
      return this.value.findIndex(checked => {
        return checked.id === item.id;
      });
    },
    // Получение данных с сервера
    showAllData: function() {
      this.loadingData = true;

      jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-read',
        query: 'sin2:/v:38947391-3994-48e0-9658-81b78c941e81',
      })
        .then(data => {
          //console.log('data', data);
        })
        .catch(err => console.error('error', err))
        .finally(() => {
          this.loadingData = false;
        });

      // fetch(this.url)
      //   .then(response => response.json())
      //   .then(res => {
      //     const result = res && res.result || [];
      //
      //     // Чекаем те элементы, что показаны на карте
      //     result.forEach(item => {
      //       item.checked = this.itemIsChecked(item) !== -1;
      //     });
      //
      //     this.data = _copy(result);
      //     this.hasData = true;
      //   })
      //   .finally(() => {
      //     this.loadingData = false;
      //   });
    },
    // Показать только активные элементы списка
    setStatus: function(item) {
      const index = this.itemIsChecked(item);

      if (index !== -1) {
        this.value.splice(index, 1);
      } else {
        this.value.push(item);
      }
    },
  },
  watch: {
    searchText: function(val) {
      if (val) {
        // Если данные еще не загружены с сервера - получаем их
        if (!this.hasData && !this.loadingData) {
          this.showAllData();
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
